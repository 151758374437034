import { Mixins, Component } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import type { VueClass } from "vue-class-component/lib/declarations";
import {
  AccountConfig,
  AccountConfigCollection,
} from "@planetadeleste/vue-mc-gw";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<AccountConfig, AccountConfigCollection>
>;

@Component
export default class AccountconfigsMixin extends Mixins(TypedModelMixin) {
  obCollection = new AccountConfigCollection();
  obModelClass = AccountConfig;
  obCollectionClass = AccountConfigCollection;
  sRoutePath = "/accountconfigs";

  created() {
    this.onCreated();
  }
}
